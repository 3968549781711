export let MenuItems: Array<any> = [
    {
        text: 'menu.dashboard',
        link: '/students/dashboards',
        class: 'primary',
        icon: 'fa fa-home'
    },
    {
        text: 'menu.about',
        link: '/students/about',
        class: 'dark',
        icon: 'fa fa-question'
    },
    {
        text: 'menu.signs',
        link: '/students/signs',
        class: 'warning',
        icon: 'fa fa-map-signs'
    },
    {
        text: 'menu.real-test',
        link: '/students/test',
        class: 'danger',
        icon: 'fa fa-clock'
    },
    {
        text: 'menu.learn',
        link: '/students/learn',
        class: 'success',
        icon: 'fa fa-book'
    },
    {
        text: 'menu.learn-videos',
        link: '/students/learn/videos',
        class: 'success',
        icon: 'fa fa-video'
    },
    {
        text: 'menu.contact',
        link: '/students/contact',
        class: 'info',
        icon: 'fa fa-phone'
    }
];

export let TeacherMenuItems: Array<any> = [
    {
        text: 'menu.dashboard',
        link: '/teachers/dashboards',
        class: 'primary',
        icon: 'fa fa-home'
    },
    {
        text: 'menu.about',
        link: '/teachers/about',
        class: 'light',
        icon: 'fa fa-question'
    },
    {
        text: 'menu.teacherStudents',
        link: '/teachers/teacher-students',
        class: 'warning',
        icon: 'fa fa-graduation-cap'
    },
    {
        text: 'menu.teachers.tests',
        link: '/teachers/test-results',
        class: 'success',
        icon: 'fa fa-clock-o'
    },
    {
        text: 'menu.settings',
        link: '/teachers/settings',
        class: 'danger',
        icon: 'fa fa-cog'
    },
    {
        text: 'menu.contact',
        link: '/teachers/contact',
        class: 'info',
        icon: 'fa fa-phone'
    }
]
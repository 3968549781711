import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { TimerComponent } from './components/timer/timer.component';
import { CoreModule } from '../core/core.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
  ],
  exports:[
    RouterModule,
    HeaderComponent,
    FooterComponent,
    TimerComponent,
    TranslateModule
  ],
  declarations: [HeaderComponent, FooterComponent, TimerComponent]
})
export class SharedModule { }

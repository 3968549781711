import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { interval } from 'rxjs';
import { takeWhile, map } from 'rxjs/operators';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {

  @Input()
  public end: Date;
  @Input()
  public show: string[] = ["hours", "minutes", "seconds"];

  @Output()
  public changed: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  public ended: EventEmitter<boolean> = new EventEmitter<boolean>();

  public value: string;

  private _alive = true;
  private _diff: number;

  private _days: number;
  private _hours: number;
  private _minutes: number;
  private _seconds: number;

  constructor() { }

  ngOnInit() {
    const com = this;
    interval(1000).pipe(takeWhile(x => com._alive), map((x) => {
      com._diff = Date.parse(com.end.toString()) - Date.parse(new Date().toString());
    })).subscribe((x) => {
      com._days = com.getDays();
      com._hours = com.getHours();
      com._minutes = com.getMinutes();
      com._seconds = com.getSeconds();

      if (com.end < new Date()) {
        com.end = null;
        if (com.ended) {
          com.ended.emit(true);
        }
        com.ngOnDestroy();
      }
      if (com.changed) {
        com.changed.emit(com.getMinutesSeconds());
      }
    });
  }

  public ngOnDestroy(): void {
    this._alive = false;
  }

  public getDays() {
    return Math.floor(this._diff / (1000 * 60 * 60 * 24));
  }

  public getHours() {
    return Math.floor((this._diff / (1000 * 60 * 60)) % 24);
  }

  public getMinutes() {
    return Math.floor((this._diff / 1000 / 60) % 60);
  }

  public getSeconds() {
    return Math.floor((this._diff / 1000) % 60);
  }

  public getMinutesSeconds(): string {
    this.value = `${(this.getMinutes() + '').padStart(2, '0')}:${(this.getSeconds() + '').padStart(2, '0')}`;
    return `${this.getMinutes()}:${this.getSeconds()}`;
  }

  public parse(v: number) {
    return v < 10 ? '0' + v : v;
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { TokenResponse } from '../models/token-response';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { UsersService } from '../services/users.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private userService: UsersService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let auth = localStorage.getItem("auth_token");
        if (auth) {
            const authHeader: TokenResponse = JSON.parse(auth);
            // Clone the request to add the new header.
            const authReq = req.clone({
                headers: req.headers.set('Authorization', authHeader.token_type + " " + authHeader.access_token)
                    .set('Content-Type', 'application/json')
                    .set('language', localStorage.getItem('language') || 'he')
            });
            // Pass on the cloned request instead of the original request.
            return next.handle(authReq).pipe(catchError(err => {
                if (err instanceof HttpErrorResponse && err.status == 401) {
                    if (this.userService.isTeacher()) {
                        this.router.navigate(['/teachers/login']);
                    } else {
                        this.router.navigate(['/students/login']);
                    }
                }
                return of(null);
            }));
        }
        else {
            return next.handle(req).pipe(catchError(err => {
                if (err instanceof HttpErrorResponse && err.status == 401) {
                    if (req.url.indexOf('token') >= 0) {
                        this.userService.loginError.emit(err);
                    }
                    else if (this.userService.isTeacher()) {
                        this.router.navigate(['/teachers/login']);
                    } else {
                        this.router.navigate(['/students/login']);
                    }
                } else if (err instanceof HttpErrorResponse && err.status == 400 && req.url.indexOf('token') >= 0) {
                    this.userService.loginError.emit(err);
                }
                return of(null);
            }));
        }
    }
}
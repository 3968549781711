import { Component, OnInit } from '@angular/core';
import { MenuItems, TeacherMenuItems } from '../../models/menu';
import { Router, ActivatedRoute, RouterEvent } from '@angular/router';
import { UsersService } from 'src/app/@modules/core/services/users.service';
import { filter } from 'rxjs/operators';
import {Location} from '@angular/common';

@Component({
  selector: 't4u-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  menuItems: any[] = [];
  public menuOpen: boolean = false;
  public currentUrl: string = '/';

  constructor(public router: Router, private route: ActivatedRoute, private usersService: UsersService, private _location: Location) {
  }

  ngOnInit() {
    if (this.usersService.isTeacher()) {
      this.menuItems = TeacherMenuItems;
    } else {
      this.menuItems = MenuItems;
    }
    this.router.events.pipe(
      filter(e => e instanceof RouterEvent)
    ).subscribe(e => {
      window.scrollTo(0, 0);
      for (let i = 0; i < this.menuItems.length; i++) {
        const item = this.menuItems[i];
        if (item.link.indexOf((e as RouterEvent).url) >= 0) {
          this.currentUrl = item.link;
        }
      }
    });
    // this.router.events.subscribe((val) => {
    //   if (!!document.querySelector('.navbar-collapse')) {
    //     document.querySelector('.navbar-collapse').classList.remove('in');

    //     var cl = document.body.classList;
    //     if (cl.contains('left-nav')) {
    //       cl.remove('left-nav');
    //     } else {
    //       cl.add('left-nav');
    //     }
    //     window.scrollTo(0, 0);
    //   }
    // });
  }

  public get currentMenuItem(): any {
    return this.menuItems.find(x => x.link === location.href.split('#')[1]);
  }

  public get showHeader(): boolean {
    return this.router.url.indexOf('login') < 0;
  }

  public get showToMainBtn(): boolean {
    return this.router && this.router.url !== '/students/dashboards' && this.router.url !== '/teachers/dashboards' && this.router.url.indexOf('login') < 0;
  }
  public get showBackBtn(): boolean {
    return this.router && this.router.url !== '/students/dashboards' && this.router.url !== '/teachers/dashboards' && this.router.url.indexOf('login') < 0;
  }


  public get showLogOffBtn(): boolean {
    return this.router && (this.router.url === '/students/dashboards' || this.router.url === '/teachers/dashboards');
  }

  menuItemSelected(item: any) {
    this.toggleMenu();
    // $(".navbar-collapse").removeClass("show");
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  public back() {
    if (this.router.url.indexOf('teachers') >= 0) {
      this.router.navigate(['/teachers']);
    } else if (this.router.url.indexOf('students') >= 0) {
      this.router.navigate(['/students']);
    }
  }

  public backOne() {
    this._location.back();
  }

  public logOff() {
    this.usersService.logout();
  }


  public get isLoggedIn(): boolean {
    return this.usersService.isLoggedIn();
  }

  public get isTeacher(): boolean {
    return this.usersService.isTeacher();
  }


  public loggedIn() {
    this.usersService.logout();
  }
}

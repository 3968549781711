import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { StudentModel } from '../models/student-model';
import { TokenResponse } from '../models/token-response';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UsersService {

  public currentUser: StudentModel;
  public loginError: EventEmitter<any> = new EventEmitter<any>();

  constructor(private httpClient: HttpClient, private router: Router, private translate: TranslateService) { }

  isLoggedIn() {
    let authToken = localStorage.getItem('auth_token');



    if (authToken && authToken.length > 0) {
      let language = localStorage.getItem('language');
      if (this.translate.currentLang != language) {
        this.translate.use(language);
      }
      if (this.currentUser == null) {
        this.currentUser = JSON.parse(localStorage.getItem('currentMember'));
      }

      if (!this.currentUser) {
        this.httpClient.get(environment.serverUrl + '/umbraco/api/MembersApi/GetCurrentMember').pipe(map(data => {
          this.currentUser = data['member'];
        }));
      }

      try {
        let authModel: TokenResponse = JSON.parse(authToken);
        return authModel && authModel.access_token && authModel.access_token.length > 0;
      } catch (e) {
        localStorage.removeItem('auth_token');
        return false;
      }
    }
    return false;
  }

  public isTeacher(): boolean {
    return this.isLoggedIn() && this.currentUser && !!this.currentUser.IsTeacher;
  }

  login(email: string, password: string, redirect = './', errorCalback: any = null) {

    const body = "grant_type=password&username=" + email + "&password=" + password;
    this.httpClient.post(environment.serverUrl + '/token', body, {
      //headers: new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8'),
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      observe: 'response'
    }).subscribe(res => {

      if (res.status < 200 || res.status >= 300) {
        errorCalback(new Error('status: ' + res.status));
      } else if (res.status === 401) {
        this.router.navigate(['./login']);
      }
      else {

        localStorage.setItem('auth_token', JSON.stringify(res.body));
        this.httpClient.get(environment.serverUrl + '/umbraco/api/MembersApi/GetCurrentMember').subscribe(data => {
          this.currentUser = data['member'];
          localStorage.setItem('currentMember', JSON.stringify(data['member']));
          if (this.currentUser.LicenseType != localStorage.getItem('licenseType')) {
            this.currentUser.LicenseType = localStorage.getItem('licenseType');
            this.httpClient.post(environment.serverUrl + '/umbraco/api/MembersApi/UpdateMember', this.currentUser)
              .subscribe(data => {
                console.log('License Type Updated!');
                this.router.navigate([redirect]);
              });
          }
          else {
            this.router.navigate([redirect]);
          }
        });
      }
    },
      err => errorCalback(err));
  }

  updateUser(redirect = './', errorCalback: any = null) {
    this.httpClient.get(environment.serverUrl + '/umbraco/api/MembersApi/GetCurrentMember').subscribe(data => {
      this.currentUser = data['member'];
      localStorage.setItem('currentMember', JSON.stringify(data['member']));
      if (this.currentUser.LicenseType != localStorage.getItem('licenseType')) {
        this.currentUser.LicenseType = localStorage.getItem('licenseType');
        this.httpClient.post(environment.serverUrl + '/umbraco/api/MembersApi/UpdateMember', this.currentUser)
          .subscribe(data => {
            console.log('User Updated!');
            this.router.navigate([redirect]);
          });
      }
      else {
        this.router.navigate([redirect]);
      }
    });
  }

  getCurrentUser(): Observable<StudentModel> {
    if (!this.currentUser) {
      return this.httpClient.get(environment.serverUrl + '/umbraco/api/MembersApi/GetCurrentMember').pipe(map(data => {
        this.currentUser = data['member'];
        return data['member'];
      }));
    }
    else {
      return of(this.currentUser);
    }
  }


  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('currentMember');
    this.router.navigate(['/students/login']);
  }

}
